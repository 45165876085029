<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="신청/승인/안전조치사항 정보 설정목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :isExcelDown="false"
          :filtering="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getSetList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <q-form ref="editForm">
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addSetting" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="LBLREMOVE"
                  icon="remove"
                  @beforeAction="deleteSetting"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveSetting"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="설정 명"
                  name="settingTitle"
                  autofocus
                  v-model="data.settingTitle">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 신청정보 -->
                <c-card title="신청정보" class="cardClassDetailForm no-margin">
                  <template slot="card-detail">
                    <div class="col-12">
                      <!-- 발급 -->
                      <c-field
                        :editable="editable && dataeditable"
                        :data="data"
                        deptValue="issuedDeptCd"
                        type="dept_user"
                        label="담당자 검토"
                        name="issuedUserId"
                        v-model="data.issuedUserId" />
                    </div>
                    <div class="col-12">
                      <!-- 승인 -->
                      <c-field
                        :editable="editable && dataeditable"
                        :data="data"
                        deptValue="approvalDeptCd"
                        type="dept_user"
                        label="팀장 승인"
                        name="approvalUserId"
                        v-model="data.approvalUserId" />
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-6">
                <!-- 승인정보 -->
                <c-card title="승인정보" class="cardClassDetailForm no-margin">
                  <template slot="card-detail">
                    <!-- 협조자-1 -->
                    <div class="col-12">
                      <c-field
                        :editable="editable && dataeditable"
                        :data="data"
                        deptValue="relationCooperation1DeptCd"
                        type="dept_user"
                        label="담당자 검토"
                        name="relationCooperation1UserId"
                        v-model="data.relationCooperation1UserId" />
                    </div>
                    <div class="col-12">
                      <!-- 협조자-2 -->
                      <c-field
                        :editable="editable && dataeditable"
                        :data="data"
                        deptValue="relationCooperation2DeptCd"
                        type="dept_user"
                        label="팀장 승인"
                        name="relationCooperation2UserId"
                        v-model="data.relationCooperation2UserId" />
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-12">
                <!-- 안전조치확인 정보 -->
                <c-card title="LBL0000247" class="cardClassDetailForm no-margin">
                  <template slot="card-detail">
                    <div class="col-6">
                      <c-field
                        :editable="editable && dataeditable"
                        :data="data"
                        deptValue="maintenanceDeptCheckDeptCd"
                        type="dept_user"
                        label="신청부서 확인"
                        name="maintenanceDeptCheckUserId"
                        v-model="data.maintenanceDeptCheckUserId" />
                    </div>
                    <div class="col-6">
                      <c-field
                        :editable="editable && dataeditable"
                        :data="data"
                        deptValue="maintenanceDeptEntryDeptCd"
                        type="dept_user"
                        label="승인부서 확인"
                        name="maintenanceDeptEntryUserId"
                        v-model="data.maintenanceDeptEntryUserId" />
                    </div>
                    <div class="col-12">
                      <c-field
                        :editable="editable && dataeditable"
                        :data="data"
                        deptValue="maintenanceDeptConfirmDeptCd"
                        type="dept_user"
                        label="확인부서"
                        name="maintenanceDeptConfirmUserId"
                        v-model="data.maintenanceDeptConfirmUserId" />
                    </div>
                  </template>
                </c-card>
                <br/>
                <br/>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sequence-manager',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'settingTitle',
            field: 'settingTitle',
            label: '설정명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        workPermitUserSettingId: '',  // 작업허가서 일련번호
        userId: '',  // 사용자아이디
        settingTitle: '',  // 설정명
        maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
        maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
        maintenanceDeptConfirmUserId: '',  // 정비부서 입회자 - 확인부서
        issuedDeptCd: '',  // 발급자 부서코드
        issuedUserId: '',  // 발급자 ID
        approvalDeptCd: '',  // 승인자 부서코드
        approvalUserId: '',  // 승인자 ID
        relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
        relationCooperation1UserId: '',  // 관련부서 협조자1 ID
        relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
        relationCooperation2UserId: '',  // 관련부서 협조자2 ID
      },
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sop.swp.workPermit.setting.insert.url,
      saveType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      this.listUrl = selectConfig.sop.swp.workPermit.setting.list.url;
      this.detailUrl = selectConfig.sop.swp.workPermit.setting.get.url;
      this.insertUrl = transactionConfig.sop.swp.workPermit.setting.insert.url;
      this.updateUrl = transactionConfig.sop.swp.workPermit.setting.update.url;
      this.deleteUrl = transactionConfig.sop.swp.workPermit.setting.delete.url;
      this.getSetList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getSetList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {userId: this.$store.getters.user.userId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.$http.url = this.detailUrl;
      this.$http.type = 'GET';
      this.$http.param = {workPermitUserSettingId: row.workPermitUserSettingId};
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addSetting() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        workPermitUserSettingId: '',  // 작업허가서 일련번호
        userId: this.$store.getters.user.userId,  // 사용자아이디
        settingTitle: '',  // 설정명
        maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
        maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
        maintenanceDeptConfirmUserId: '',  // 정비부서 입회자 - 확인부서
        issuedDeptCd: '',  // 발급자 부서코드
        issuedUserId: '',  // 발급자 ID
        approvalDeptCd: '',  // 승인자 부서코드
        approvalUserId: '',  // 승인자 ID
        relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
        relationCooperation1UserId: '',  // 관련부서 협조자1 ID
        relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
        relationCooperation2UserId: '',  // 관련부서 협조자2 ID
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        workPermitUserSettingId: '',  // 작업허가서 일련번호
        userId: this.$store.getters.user.userId,  // 사용자아이디
        settingTitle: '',  // 설정명
        maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
        maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
        maintenanceDeptConfirmUserId: '',  // 정비부서 입회자 - 확인부서
        issuedDeptCd: '',  // 발급자 부서코드
        issuedUserId: '',  // 발급자 ID
        approvalDeptCd: '',  // 승인자 부서코드
        approvalUserId: '',  // 승인자 ID
        relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
        relationCooperation1UserId: '',  // 관련부서 협조자1 ID
        relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
        relationCooperation2UserId: '',  // 관련부서 협조자2 ID
      };
    },
    saveSetting() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteSetting() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.workPermitUserSettingId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSetList();
      this.reset();
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSetList();
      this.reset();
    },
  }
};
</script>
