var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "신청/승인/안전조치사항 정보 설정목록",
                tableId: "table",
                columnSetting: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                isExcelDown: false,
                filtering: false,
              },
              on: { rowClick: _vm.rowClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "LBLSEARCH", icon: "search" },
                        on: { btnClicked: _vm.getSetList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLDETAIL" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "LBLREG", icon: "add" },
                                on: { btnClicked: _vm.addSetting },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.deleteable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.deleteUrl,
                                  isSubmit: true,
                                  param: _vm.data,
                                  mappingType: "DELETE",
                                  label: "LBLREMOVE",
                                  icon: "remove",
                                },
                                on: {
                                  beforeAction: _vm.deleteSetting,
                                  btnCallback: _vm.deleteCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.saveable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveSetting,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "설정 명",
                            name: "settingTitle",
                            autofocus: "",
                          },
                          model: {
                            value: _vm.data.settingTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "settingTitle", $$v)
                            },
                            expression: "data.settingTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "신청정보" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      data: _vm.data,
                                      deptValue: "issuedDeptCd",
                                      type: "dept_user",
                                      label: "담당자 검토",
                                      name: "issuedUserId",
                                    },
                                    model: {
                                      value: _vm.data.issuedUserId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "issuedUserId", $$v)
                                      },
                                      expression: "data.issuedUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      data: _vm.data,
                                      deptValue: "approvalDeptCd",
                                      type: "dept_user",
                                      label: "팀장 승인",
                                      name: "approvalUserId",
                                    },
                                    model: {
                                      value: _vm.data.approvalUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "approvalUserId",
                                          $$v
                                        )
                                      },
                                      expression: "data.approvalUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "승인정보" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      data: _vm.data,
                                      deptValue: "relationCooperation1DeptCd",
                                      type: "dept_user",
                                      label: "담당자 검토",
                                      name: "relationCooperation1UserId",
                                    },
                                    model: {
                                      value:
                                        _vm.data.relationCooperation1UserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "relationCooperation1UserId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.relationCooperation1UserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      data: _vm.data,
                                      deptValue: "relationCooperation2DeptCd",
                                      type: "dept_user",
                                      label: "팀장 승인",
                                      name: "relationCooperation2UserId",
                                    },
                                    model: {
                                      value:
                                        _vm.data.relationCooperation2UserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "relationCooperation2UserId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.relationCooperation2UserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "LBL0000247" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      data: _vm.data,
                                      deptValue: "maintenanceDeptCheckDeptCd",
                                      type: "dept_user",
                                      label: "신청부서 확인",
                                      name: "maintenanceDeptCheckUserId",
                                    },
                                    model: {
                                      value:
                                        _vm.data.maintenanceDeptCheckUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "maintenanceDeptCheckUserId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.maintenanceDeptCheckUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      data: _vm.data,
                                      deptValue: "maintenanceDeptEntryDeptCd",
                                      type: "dept_user",
                                      label: "승인부서 확인",
                                      name: "maintenanceDeptEntryUserId",
                                    },
                                    model: {
                                      value:
                                        _vm.data.maintenanceDeptEntryUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "maintenanceDeptEntryUserId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.maintenanceDeptEntryUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      data: _vm.data,
                                      deptValue: "maintenanceDeptConfirmDeptCd",
                                      type: "dept_user",
                                      label: "확인부서",
                                      name: "maintenanceDeptConfirmUserId",
                                    },
                                    model: {
                                      value:
                                        _vm.data.maintenanceDeptConfirmUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "maintenanceDeptConfirmUserId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.maintenanceDeptConfirmUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c("br"),
                        _c("br"),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }